import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_pro.js";
import { Link } from "gatsby";
import { TrailguidePluginOld } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`TrailDigitize and present all your outdoor activities in trailguide to help your
guests and locals to find them and be guided through. All with one easy
accessible web-app!`}</p>
    <ul>
      <li parentName="ul">{`Present your outdoor activities.`}</li>
      <li parentName="ul">{`Reach your customers.`}</li>
      <li parentName="ul">{`Provide an info & navigation app to your guests.`}</li>
      <li parentName="ul">{`Get feedback and statistics.`}</li>
      <li parentName="ul">{`Connect print products to your digital content.`}</li>
      <li parentName="ul">{`Save costs`}</li>
      <li parentName="ul">{`Manage your infrastructure & traffic.`}</li>
    </ul>
    <br />
    <Link to="/pro/login" mdxType="Link">How to Log in to the management portal</Link>
    <br />
    <Link to="/pro/upload" mdxType="Link">How to upload an activity</Link>
    <br />
    <Link to="/pro/activitytype" mdxType="Link">How to select the type of activity</Link>
    <br />
    <Link to="/pro/gpstrack" mdxType="Link">The GPS track file (.gpx)</Link>
    <br />
    <Link to="/pro/description" mdxType="Link">How to add trail description and details</Link>
    <br />
    <Link to="/pro/touraccess" mdxType="Link">How to add tours and access roads</Link>
    <br />
    <Link to="/pro/picturesvideos" mdxType="Link">How to add pictures and videos</Link>
    <br />
    <Link to="/pro/transfer" mdxType="Link">How to transfer your content to your destination</Link>
    <br />
    <Link to="/pro/map" mdxType="Link">How to create a custom map</Link>
    <br />
    <Link to="/pro/mapbyhand" mdxType="Link">
  How to select specific activities for a custom map
    </Link>
    <br />
    <Link to="/pro/mapbysearch" mdxType="Link">How to select activities by a search query</Link>
    <br />
    <Link to="/pro/mapevent" mdxType="Link">How to create a time-limited map for events</Link>
    <br />
    <Link to="/pro/embed" mdxType="Link">How to embed a map on your web site</Link>
    <br />
    <h3>{`Example activity`}</h3>
    <p>{`Below is an example mountain bike singletrack including points of interests
and a link to the tour with the whole loop. Click on the track on the map or
in the list to open the description.`}</p>
    <TrailguidePluginOld code="3p" mdxType="TrailguidePluginOld" />
    <Link className="float-right" to="/pro/login" mdxType="Link">
  Log in to the management portal →
    </Link>
    <br className="clear-both" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      